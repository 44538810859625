import logo from './logo.svg';
import './App.css';
import Coming from './pages/commingsoon/Coming'
function App() {
  return (
    <div className="app">
      <Coming/>
    </div>
  );
}

export default App;
