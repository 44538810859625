import React from "react";
import "./coming.scss";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../images/logo.png";
import image from "../../images/coming.png";

const Coming = () => {
  return (
    <div className="coming">
      <div className="top"></div>
      <div className="left">
        <img className="logo" src={logo} alt="" />

        <div className="container">
          <p className="title1">OUR WEBSITE IS COMING SOON</p>
          <p className="title2">We are preparing our website for you</p>
          <a href="mailto:contact@formactive.center?subject = Feedback&body = Message">
            Contact Us
          </a>
        </div>

        <div className="social-media">
          <div className="icons">
            <FacebookRoundedIcon
              className="facebook"
              onClick={() =>
                window.open(
                  "https://web.facebook.com/Formactive-Center-109080435077440"
                )
              }
            />

            <InstagramIcon
              className="instagram"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/accounts/login/?next=/formactive.center/"
                )
              }
            ></InstagramIcon>

            <LinkedInIcon
              className="linkedin"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/formactive-center-79b074236/"
                )
              }
            />

            <YouTubeIcon className="youtube" />
          </div>
        </div>
      </div>
      <div className="right">
        <img className="image" src={image} alt="" />
      </div>
    </div>
  );
};

export default Coming;
